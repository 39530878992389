<template>
    <div>
        <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#005f32"
            spinner="bar-fade-scale" />
        <ServerError v-if="ServerError" />
        <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
            <v-layout wrap justify-center>
                <v-flex  class="align-self-center">
                    <span style="color: #ffffff">
                        {{ msg }}
                    </span>
                </v-flex>
                <v-flex text-right>
                    <v-btn small :ripple="false" text @click="showSnackBar = false">
                        <v-icon style="color: #ffffff">mdi-close</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-snackbar>
        <v-layout wrap>
            <v-flex xs12>
                <v-layout wrap>
                    <v-flex xs12 pa-9> <span style="font-weight:bold;" class="popregular ml">Stories of Impact</span></v-flex>
                    <v-flex xs12 pb-5>
                        <v-btn color="#e27725">
                            <span class="popregular" style="color:white">
                                FEATURED SUPPORT

                            </span>
                        </v-btn>
                    </v-flex>
                    <v-flex xs12 pt-10 pb-10>
                        <v-layout wrap justify-center>
                            <v-flex  xs11 lg3 pl-4>
                                <v-layout wrap justify-center>
                                    <v-flex xs12 class="avatar-container">
                                        <v-avatar color="black" size="100" class="avatar">
                                            <v-icon color="red">mdi-account</v-icon>
                                        </v-avatar>
                                        <v-card class="card">
                                            <v-layout wrap justify-center>
                                                <v-flex xs12>
                                                    <span>Card Title</span>

                                                </v-flex>
                                                <v-flex xs11 pt-7>
                                                    <span>

                                                    </span>
                                                </v-flex>
                                                <v-flex xs12>
                                                    <v-expansion-panels flat>
                                                        <v-expansion-panel>
                                                            <v-expansion-panel-header>
                                                                On the afternoon of August 9, 2023, while on
                                                                his routine forest patrol, Mr. Mahesh Soni,
                                                            </v-expansion-panel-header>
                                                            <v-expansion-panel-content>
                                                                On the afternoon of August 9, 2023, while on
                                                                his routine forest patrol, Mr. Mahesh Soni,
                                                                a
                                                                dedicated 61-year-old Forester due for
                                                                retirement next year, encountered a man

                                                            </v-expansion-panel-content>
                                                        </v-expansion-panel>
                                                    </v-expansion-panels>
                                                </v-flex>
                                            </v-layout>

                                        </v-card>
                                    </v-flex>

                                </v-layout>
                            </v-flex>
                            <v-flex xs11 lg3 pt-10 pt-lg-0 pl-4>
                                <v-layout wrap justify-center>
                                    <v-flex xs12 class="avatar-container">
                                        <v-avatar color="black" size="100" class="avatar">
                                            <v-icon color="red">mdi-account</v-icon>
                                        </v-avatar>
                                        <v-card class="card">
                                            <v-layout wrap justify-center>
                                                <v-flex xs12>
                                                    <span>Card Title</span>

                                                </v-flex>
                                                <v-flex xs11 pt-7>
                                                    <span>

                                                    </span>
                                                </v-flex>
                                                <v-flex xs12>
                                                    <v-expansion-panels flat>
                                                        <v-expansion-panel>
                                                            <v-expansion-panel-header>
                                                                On the afternoon of August 9, 2023, while on
                                                                his routine forest patrol, Mr. Mahesh Soni,
                                                            </v-expansion-panel-header>
                                                            <v-expansion-panel-content>
                                                                On the afternoon of August 9, 2023, while on
                                                                his routine forest patrol, Mr. Mahesh Soni,
                                                                a
                                                                dedicated 61-year-old Forester due for
                                                                retirement next year, encountered a man

                                                            </v-expansion-panel-content>
                                                        </v-expansion-panel>
                                                    </v-expansion-panels>
                                                </v-flex>
                                            </v-layout>

                                        </v-card>
                                    </v-flex>

                                </v-layout>
                            </v-flex>
                            <v-flex xs11 lg3 pt-10 pt-lg-0 pl-4>
                                <v-layout wrap justify-center>
                                    <v-flex xs12 class="avatar-container">
                                        <v-avatar color="black" size="100" class="avatar">
                                            <v-icon color="red">mdi-account</v-icon>
                                        </v-avatar>
                                        <v-card class="card">
                                            <v-layout wrap justify-center>
                                                <v-flex xs12>
                                                    <span>Card Title</span>

                                                </v-flex>
                                                <v-flex xs11 pt-7>
                                                    <span>

                                                    </span>
                                                </v-flex>
                                                <v-flex xs12>
                                                    <v-expansion-panels flat>
                                                        <v-expansion-panel>
                                                            <v-expansion-panel-header>
                                                                On the afternoon of August 9, 2023, while on
                                                                his routine forest patrol, Mr. Mahesh Soni,
                                                            </v-expansion-panel-header>
                                                            <v-expansion-panel-content>
                                                                On the afternoon of August 9, 2023, while on
                                                                his routine forest patrol, Mr. Mahesh Soni,
                                                                a
                                                                dedicated 61-year-old Forester due for
                                                                retirement next year, encountered a man

                                                            </v-expansion-panel-content>
                                                        </v-expansion-panel>
                                                    </v-expansion-panels>
                                                </v-flex>
                                            </v-layout>

                                        </v-card>
                                    </v-flex>

                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
                <v-layout wrap justify-center>
                    <v-flex xs11>
                        <v-layout wrap justify-center pt-7>
        <v-flex xs11 >
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap>
                <v-flex xs12 align-self-center text-center py-4>
                  <span style="font-weight:bold;" class="popregular ml">Testimonials</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 lg12 py-7 v-if="icons">
              <v-carousel  
  
    cycle :interval="5000" height="auto" hide-delimiter-background>
                <v-carousel-item v-for="(item, i) in icons" :key="i">
                  <v-card outlined>
                    <v-layout wrap pa-2 justify-center class="popregular">
                          <v-flex xs6 sm6 md2 lg1 xl2 text-center>
                            <v-avatar size="100%">
                              <v-img :src="item.name"></v-img>
                            </v-avatar>
                          </v-flex>
                      <v-flex xs10 style="font-size:17px;font-weight:bolder" text-center>
                        <v-layout wrap justify-center>
                            <v-flex xs6 lg2 pt-3 text-right>
                                {{ item.heading }}

                            </v-flex>
                            <v-flex pl-3 xs6 lg2 pt-3 text-left style="color:#e27725">{{ item.location }}</v-flex>
                      <v-flex xs11 pt-4 pt-3 text-center style="font-size:14px;text-align: justify;">{{ item.text }}</v-flex>
                        </v-layout>
                      </v-flex>
                      
                    </v-layout>
                  </v-card>
                </v-carousel-item>
              </v-carousel>
            </v-flex>
          </v-layout>
  
        </v-flex>
      </v-layout>
                    </v-flex>
                </v-layout>
                <v-layout wrap justify-center>
                      <v-flex xs12 pt-16 id="faq-section">
        <v-layout wrap justify-center>
          <v-flex xs11  >
            <span style="font-weight:bold;" class="popregular ml">Frequently Asked Questions </span>
          </v-flex>

          <v-flex xs11  pt-2 >
            <v-layout wrap>

              <v-flex xs12 pt-3 style="text-align: justify;">
                <template>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="popregular medium"
                        style="color: black;font-weight: bold;font-size: 16px;">
                     What are the roles and responsibilities of frontline forest staff?
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;">
                        The frontline forest staff regularly walk and patrol protected areas to deter illegal activities like poaching, logging or encroachment. Each beat guard is
responsible for vast areas – 1,200 to 3,700 acres of land. They prevent and manage forest fires, especially during the dry season. They also engage in reforestation and
habitat restoration efforts to maintain and enhance biodiversity. They work closely with the local communities, educating them about forest conservation and
sustainable forest management, and to avert human-animal conflict. They enforce environmental laws and regulations and also gather data on wildlife population,
habitat health and other ecological factors to inform conservation efforts and research.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-flex>
              <v-flex xs12 pt-5 style="text-align: justify;">
                <template>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="popregular medium"
                        style="color: black;font-weight: bold;font-size: 16px;">
                       What are the challenges faced by the frontline forest staff in India?
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;">
                     Answer: They face a constant threat to life from well-armed poachers who are engaged in the lucrative business of illegal wildlife trade, and from accidental
encounters with wild animals. These foot-soldiers bear the maximum brunt of changing weather patterns and extreme events such as wildfires, which they have to
mitigate.<br /><br />
To add, they work with limited resources, including inadequate equipment, in some of the most remote locations and physically demanding environments, often
without access to basic amenities and healthcare facilities. They stay away and disconnected from their families for weeks at length.
<br /><br />They often struggle with a lack of support from the very communities they serve due to the rising human-animal conflict over land and resources. To top it all, these
unsung heroes receive inadequate compensation and benefits. This is especially true for the temporary staff and daily wagers, most of whom spend decades working
part-time.<br /><br />
These challenges can potentially demotivate these otherwise dedicated men and women committed to preserving India’s natural heritage.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-flex>
              <v-flex xs12 pt-5 style="text-align: justify;">
                <template>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="popregular medium"
                        style="color: black;font-weight: bold;font-size: 16px;">
                       What does WTI do to support India’s frontline forest staff?
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;">
                      Apart from providing regular trainings and adequate equipment support to the frontline forest staff working across India, WTI is the only Indian NGO that runs
a Supplementary Accident Assurance Scheme to cover forest staff against accidental death or injury on duty. The immediate financial, ex-gratia support pays their
medical bills in case of injuries and provides some support to their families in their most difficult times. In many cases, these individuals are the sole breadwinners for
their families making the financial support invaluable.<br /><br />
This multi-disciplinary approach is to stand in support and boost the morale of these unsung guardians of the wild.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-flex>
              <v-flex xs12 pt-5 style="text-align: justify;">
                <template>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="popregular medium"
                        style="color: black;font-weight: bold;font-size: 16px;">

                       How many frontline forest staff are covered under WTI’s ex-gratia scheme?


                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;">
                       WTI covers the entire frontline forest staff of India under the ex-gratia scheme, including both the permanent staff as well the contractual, daily wage earners.
At present, over 21,000 frontline forest staff are a part of our database and covered under the scheme.

                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-flex>
              <v-flex xs12 pb-10 pt-5 style="text-align: justify;">
                <template>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="popregular medium"
                        style="color: black;font-weight: bold;font-size: 16px;">
                       How will my contribution be utilised?

                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;">
                        WTI maintains a rigorous financial accountability system to ensure that every donation directly supports our program providing ex-gratia financial support to
frontline forest staff. Your contribution to this initiative will provide crucial financial assistance to the forest staff who have been injured or to the families of those who
have tragically lost their lives while on duty. This support encompasses medical expenses, financial aid to the bereaved families, and other essential assistance. Through
these efforts, we aim to honour their dedication and sacrifice in safeguarding India’s forests and wildlife. Your contribution is greatly appreciated and will make a
significant impact.<br /><br />
To stay updated on the progress and impact of this initiative and other WTI projects, we encourage you to SUBSCRIBE to the WTI Newsletter.

                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-flex>
            
            </v-layout>

          </v-flex>
        </v-layout>
        <v-layout class="popregular medium" style="color: black;font-size: 14px;text-align: justify;"  pb-10 wrap justify-center>
            <v-flex xs11 >
                <span style="font-weight:bold;" class="popregular ml">Featured Support </span>

            </v-flex>
            <v-flex xs11  pt-3>
                <span>
                    In the heart of South Betul Division in Madhya Pradesh, there exists a man of remarkable courage and dedication – Mr. Yadunandan Yadav, a Forester with an unwavering commitment
to the preservation of our forests.
                </span>
                <br />
                <br />
                <span>
                    Mr. Yadav&#39;s story begins with a familial connection to the world of forestry. His father served as a Range Forest Officer (RFO) with the forest department. Tragically, Mr. Yadav&#39;s father
lost his life on duty in 1984, leaving behind a legacy of service and a son who would carry it forward.
                </span>
            </v-flex>
            <v-flex xs12 lg11 pa-6>
                <v-layout wrap justify-center>
                    <v-flex xs12 lg5 >
        <v-img src="../assets/Images/fox.jpg">
</v-img>
                    </v-flex>
                </v-layout>
            </v-flex>
<v-flex xs11 >
    <span>
        In 1985, Mr. Yadav was employed on compassionate grounds, starting as a humble peon. His determination and dedication saw him rise through the ranks, eventually becoming a full-
time Forester in the south-Betul Division, a role that demands both commitment and courage. His duties are multifaceted, encompassing patrolling the forests to protect them from
illegal activities and fulfilling various official responsibilities at the department office.
    </span><br /><br />
    <span>
        On the fateful night of April 12, 2023, the forest department received information that intruders from Maharashtra were planning to fell trees along the Khomai-Andherbadi marg at the
MP-Maharashtra border. Armed with only a stick and an axe for his defense, Mr. Yadav accompanied a team of 6-7 staff to the scene. At 6:30 the next morning, when most of us were
still in the embrace of sleep at home, they encountered 20 individuals exiting the forest with stolen branches. When the team attempted to intervene, the fellers resorted to violence,
pelting stones at the officials.
    </span>
</v-flex>
<v-flex xs12 lg11 pa-6>
                <v-layout wrap justify-center>
                    <v-flex xs12 lg5 >
        <v-img src="../assets/Images/kingfisher.jpg">
</v-img>
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-flex xs11 >
                <span>
                    During the confrontation, Mr. Yadav was struck by a stone with such force that it caused him to lose his balance and tumble down the hilly terrain. The fall left him with severe injuries.
He was transported to Suretech Hospital in Nagpur, where he underwent treatment for his spinal injury.
                </span>
                <br /><br />
                <span>
                    WTI extended immediate ex-gratia support to Mr. Yadav, for which he expressed profound gratitude. He shared, &#39;As the sole breadwinner for my family of four, including my mother,
wife, and two children, the prompt financial assistance was instrumental in helping us through that challenging period.
                </span>
                <br /><br />
                <span>With his medical bills taken care of, Mr. Yadav has since made a remarkable recovery and has returned to his duties, continuing to protect the forests he loves.
</span>
            </v-flex>
        </v-layout>
      </v-flex>
      
                </v-layout>
             
            </v-flex>
        </v-layout>


    </div>
</template>
<script>
import axios from "axios";
export default {
    data() {
        return {
            Newaddress: '',
            Newcity: "",
          icons: [
            {
              name: require("../assets/Images/account.jpg"),
              heading: "Emily Turner",
              location: "WTI Donor",
              text: '"I feel honoured to be a part of WTIs initiative to support the frontline forest staff and their families. Its heart-warming to see an organization take such concrete steps to ensure the well-being of those who protect our natural heritage. Knowing that our contributions are making a difference in the lives of these brave individuals and their loved ones is truly fulfilling."',
            },
            {
              name: require("../assets/Images/account.jpg"),
              heading: "Mark Anderson",
              location: "WTI Donor",
              text: '"Supporting this initiative has been a deeply rewarding experience. The commitment WTI shows to the welfare of the frontline staff is truly remarkable. Its an investment in the people who are on the frontlines safeguarding our forests, wildlife, and the environment. Im proud to be a part of this initiative, knowing that our contributions are providing immediate help to those who need it most.""',
            },
            {
              name: require("../assets/Images/account.jpg"),
              heading: "Sarah Davis",
              location: "WTI Donor",
              text: '"It’s a privilege to be a part of this community that supports the brave frontline forest staff and their families. WTIs dedication to their welfare is evident, and Im inspired by the positive impact we can make together."',
            },
          ],
            Newpincode: "",
            Newpannumber: "",
            items: [
          {
            imageUrl: 'your-image-1.jpg',
            title: 'Slide 1',
            description: 'Description for Slide 1',
          },
          {
            imageUrl: 'your-image-2.jpg',
            title: 'Slide 2',
            description: 'Description for Slide 2',
          },
          // Add more items as needed
        ],
            //inherited varibles from donation Card
            BTN1: 5000,
            BTN2: 10000,
            BTN3: 20000,
            sign: "₹",
            Cname: "INR",

            country: "India",
            amount: null,
            firstname: null,
            lastname: null,
            mobile: null,
            email: null,
            address: null,
            city: null,
            state: null,
            postalcode: null,
            panno: null,
            hearaboutus: "",
            comments: null,
            isanonymous: false,
            subscribe: false,
            taxExemption: false,
            symbol: "₹",
            regionssymbol: [],
            message: "",
            resource: null,
            regions: [],





            //end of inherited variables from donationCard
            rules: {
                required: (value) => !!value || "Required.",
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail.";
                },
            },
            donation: 5000,
            firstCard: true,
            expansionItems: [
                { title: "Item 1", content: "Content for Item 1" },
                { title: "Item 2", content: "Content for Item 2" },
                // Add more items as needed
            ],
            secondCard: false,
            radio: null,
            showSnackBar: false,
            msg: "",
            ServerError: false,
            timeout: 3000,
            appLoading: false,
        };
    },
    watch: {
        country() {
            if (this.country != "India") {
                this.BTN1 = 100;
                this.BTN2 = 200;
                this.BTN3 = 500;
                this.sign = "$";
                this.Cname = "USD";
                this.donation = this.BTN1;
                //  console.log("true not india")
            } else {
                this.BTN1 = 5000;
                this.BTN2 = 10000;
                this.BTN3 = 20000;
                this.sign = "₹";
                this.Cname = "INR";

                this.donation = this.BTN1;

                //  console.log("false india")
            }
        },
    },
    beforeMount() {
        axios({
            method: "GET",
            url: "/country/all",
        })
            .then((response) => {
                this.regions = response.data.data;
            })
            .catch((err) => {
                console.log(err);
            });
        // this.getData();
    },
    methods: {

        login() {
            this.$gtag.event('login', { method: 'Google' })
        },


        toTextField() {
            this.donation = null;
            document.getElementById("my_button").onclick = function () {
                document.getElementById("my_textbox").focus();
            };
        },
        validate() {
            if (this.$refs.file.validate()) {
                var don = {};
                this.appLoading = true;
                don["country"] = this.country;
                don["amount"] = this.donation;
                don["firstname"] = this.firstname;
                don["lastname"] = this.lastname;
                // don["donationtype"] = this.$route.query.DonationName;
                don["email"] = this.email;
                don["isanonymous"] = this.isanonymous;
                don["subscribe"] = this.subscribe;
                don["address"] = this.Newaddress;
                don["city"] = this.Newcity;
                // don["pincode"] = this.Newpincode;
                // don["panNumber"] = this.Newpannumber;
                don["taxExemption"] = this.taxExemption;
                don["postalcode"] = this.Newpincode;
                don["panno"] = this.Newpannumber;

                // taxExemption
                axios({
                    method: "post",
                    url: "/payment/initiate",
                    data: don,
                })
                    .then((response) => {
                        if (response.data.status) {
                            localStorage.setItem("currencySymbol", this.symbol);
                            this.appLoading = false;
                            this.$router.push(
                                "/payment/?id=" +
                                response.data.id +
                                "&country=" +
                                this.country +
                                "&event=" +
                                this.$route.query.DonationName
                            );
                        } else {
                            this.appLoading = false;
                            this.msg = response.data.msg;
                            this.showSnackBar = true;
                        }
                    })
                    .catch(() => {
                        this.appLoading = false;
                        this.ServerError = true;
                    });
            } else {
                this.showSnackBar = true;
                this.msg = "Please complete your form";
            }
        },
    },
};
</script>
<style  scoped>
.LBL>>>label {
    font-family: poppinsregular !important;
    font-size: 12px !important;

}

.v-text-field--outlined>>>fieldset {
    border-color: grey;
    border-radius: 5px;
}

.v-radio>>>label {
    font-size: 20px;
    font-family: poppinsregular;
    color: black;
}

.center>>>input {
    text-align: center;
    font-family: poppinsregular;
    font-size: 32px;
}

.center2>>>label {
    text-align: center;
    font-family: poppinsregular;
    font-size: 20px;
}

.center4 {
    text-align: center !important;
    font-family: poppinsregular !important;

}

.boldR>>>label {
    font-family: poppinsbold;
}

.animate {
    position: relative;
    animation: mymove 0.25s;
}

.animate2 {
    position: relative;
    animation: mymove2 0.25s;
}

#div2 {
    animation-timing-function: linear;
}

@keyframes mymove2 {
    from {
        left: -200px;
    }

    to {
        left: 0px;
    }
}

@keyframes mymove {
    from {
        right: -500px;
    }

    to {
        right: 0px;
    }
}

#div1 {
    animation-timing-function: linear;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear;
    /* Safari */
    animation: spin 2s linear;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.v-checkbox>>>label {
    font-family: poppinsregular;
}

.avatar-container {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.card {
    margin-top: 50px;
    /* Adjust the margin to control the distance between the avatar and card */
    z-index: 1;
}
</style>